angular.module('fingerink')
    .factory('fingerinkService', ['$http', 'session', function ($http, session) {
        var sdo = {
            getCountries: function () {
                return $http({
                    method: 'GET',
                    url: 'countries',
                    crossDomain: true
                });
            }

        };
        return sdo;
}]);
